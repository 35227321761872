import { CacheType, createObservableDataAction, IAction, IActionContext, IActionInput } from '@msdyn365-commerce/core';
import { BingMapsLocationsResult } from '../../models/bing-maps-data';
import { GeoLocationPostalCode } from '../../models/search-by-lat-lon-data';

/**
 * Input class for the azure maps search fuzzy API
 */
export class BingMapsLocationsInput implements IActionInput {
    public latitude: string;
    public longitude: string;
    public serviceEndpoint: string;
    public apiKey?: string;

    constructor(lat: string, lon: string, apiKey: string, endPoint: string) {
        this.latitude = lat;
        this.longitude = lon;
        this.apiKey = apiKey;
        this.serviceEndpoint = endPoint;
    }

    public getCacheKey = () => `BingMapsLocationAPI-${this.latitude}-${this.longitude}`;
    public getCacheObjectType = () => 'BingMapsLocationAPIResponse';
    public dataCacheType = (): CacheType => 'none'; // Don't cache this. Its against bing maps TOS
}

/**
 * Calls azure maps search fuzzy API
 */
export async function getLocations(input: BingMapsLocationsInput, ctx: IActionContext): Promise<GeoLocationPostalCode | undefined> {
    if (!input.apiKey) {
        ctx.trace(`[BingMapsLocations] No Bing Maps API key provided`);

        return undefined;
    }

    const callbackString = `bingMapsSearchCallback_${crypto.getRandomValues(new Uint32Array(1))[0]}`;

    const requestUrl =
        `${encodeURI(input.serviceEndpoint)}/${encodeURI(input.latitude)},${encodeURI(input.longitude)}?key=${encodeURI(input.apiKey)}&jsonp=${callbackString}`;

    return new Promise<GeoLocationPostalCode | undefined>((resolve) => {
        const script = document.createElement('script');
        script.setAttribute('type', 'text/javascript');
        script.setAttribute('src', requestUrl);

        window[callbackString] = (data: BingMapsLocationsResult) => {
            delete window[callbackString];
            document.body.removeChild(script);
            if (data && data.resourceSets && data.resourceSets.length > 0 && data.resourceSets[0].resources.length > 0) {
                resolve({
                    postalCode: data.resourceSets[0].resources[0].address.postalCode
                });
            }
            resolve(undefined);
        };
        document.body.appendChild(script);
    });
}

export default createObservableDataAction({
    action: <IAction<GeoLocationPostalCode | undefined>>getLocations,
});
